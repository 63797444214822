<template>
  <div>
    <b-row>
      <b-col md="12">
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0">

                <label>{{ $t("labels.show") }}</label>
                <v-select
                    :name="perPage"
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    @input="fetchData"
                    class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t("labels.entries") }}</label>
              </b-col>

              <!--              <b-col md="4"-->
              <!--                     class="d-flex align-items-center justify-content-end  mb-1">-->
              <!--                <label class="px-2">{{ 'Filtrar solicitud' }}</label>-->
              <!--                <b-form-checkbox name="check-button"-->
              <!--                                 v-model="authorized"-->
              <!--                                 @change="fetchData"-->
              <!--                                 class="d-inline-block"-->
              <!--                                 switch-->
              <!--                                 inline>-->
              <!--                </b-form-checkbox>-->
              <!--              </b-col>-->

              <b-col
                  md="6"
                  class="d-flex align-items-end justify-content-center mb-1"
              >
                <b-form-input
                    v-model="searchQuery"
                    @input="fetchData"
                    class="d-inline-block mr-1"
                    :placeholder="$t('labels.search')"
                />
              </b-col>
            </b-row>
          </div>

          <b-overlay
              :show="loadings.overlay"
              :variant="$store.state.appConfig.layout.skin"
              blur="2"
              spinner-type="grow"
              class="p-50"
              opacity="0.50"
          >

            <b-table
                style="min-height: 300px"
                ref="refUserListTable"
                :items="revocationList"
                responsive
                :fields="tableColumns"
                primary-key="_id"
                :sort-by.sync="sortBy"
                show-empty
                :empty-text="$t('messages.nomatching')"
                :sort-desc.sync="isSortDirDesc"
            >

              <template #cell(user)="data">
                <div>
                  {{ data.item.user.userName }}
                </div>
              </template>

              <template #cell(createdAt)="data">
                <div>
                  {{ formatDate(data.item.createdAt) }}
                </div>
              </template>


              <template #cell(authorized)="data">
                <div v-if="!data.item.authorized" class="d-flex justify-content-start">
                  <b-button @click="approveRevocation(data)" variant="outline-primary">
                    {{ $t('labels.revoke') }}
                  </b-button>
                </div>
              </template>


            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
              <span class="text-muted">
                {{ $t("labels.showing") }}
                {{ dataMeta.from }}
                {{ $t("labels.to") }}
                {{ dataMeta.to }}
                {{ $t("labels.of") }}
                {{ dataMeta.of }}
                {{ $t("labels.entries") }}
              </span>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>
<script>

import {BBadge, BButton, BCard, BCol, BFormInput, BOverlay, BRow, BTable} from "bootstrap-vue";
import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch
} from '@vue/composition-api'
import {useUtils} from "@core/libs/acl";
import vSelect from "vue-select";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import store from "@/store";
import revocationStoreModule from "@/views/pages/revocation/revocationStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";
import moment from "moment";
import { translatableText } from '@core/utils/utils'
import { axiosErrorHandle } from '@core/utils/errorHandler'

const {t} = useI18nUtils();

const {isSeePermission} = useUtils();
export default {
  name: "index",
  components: {
    BBadge,
    BTable,
    BOverlay,
    BFormInput,
    vSelect,
    BButton
  },

  setup() {
    const vm = getCurrentInstance()

    const REVOCATION_APP_STORE_MODULE_NAME = "app-revocation-list";

    if (!store.hasModule(REVOCATION_APP_STORE_MODULE_NAME))
      store.registerModule(
          REVOCATION_APP_STORE_MODULE_NAME,
          revocationStoreModule
      );

    onUnmounted(() => {
      if (store.hasModule(REVOCATION_APP_STORE_MODULE_NAME))
        store.unregisterModule(REVOCATION_APP_STORE_MODULE_NAME);
    });


    //hooks
    onMounted(() => {
      fetchData();
    });

    const totalRows = ref(0);
    const perPage = ref(10);
    const perPageOptions = ref([10, 25, 50, 100]);
    const searchQuery = ref("");
    const authorized = ref(false);
    const currentPage = ref(1);
    const toast = useToast(ToastificationContent);

    const dataMeta = ref({
      from: 0,
      to: 0,
      of: 0,
    });

    // loadings
    const loadings = reactive({
      overlay: false,
      button: false,
    });

    watch(
      store.state["app-revocation-list"].revocationNotification,
      (val) => {
        if (val) {
          fetchData()
        }
      }
    );

    /**
     * set active
     */
    const fetchData = () => {
      loadings.overlay = true;

      const params = {
        sortDesc: isSortDirDesc.value,
        perPage: perPage.value,
        authorized: authorized.value,
        page: currentPage.value,
        q: searchQuery.value,
      };

      store
          .dispatch('app-revocation-list/fetchRevocations', params)
          .then(response => {
            revocationList.value = response.data.exclusionRevocations;

            fillDataMeta(response)

            loadings.overlay = false
          })
          .catch(async (e) => {
            toast({
              component: ToastificationContent,
              props: {
                title: await translatableText({
                  text: axiosErrorHandle(e)
                }),
                icon: "XIcon",
                variant: "danger",
              },
            });
          })
    }

    const revocationList = ref([]);

    const sortBy = ref("user");

    const isSortDirDesc = ref(false);

    const tableColumns = computed(()=> [
      {key: "user", sortable: true, label: t('labels.player')},
      {key: "createdAt", label: t('labels.active_date')},
      {key: "authorized", label: t("labels.application")}
    ]);


    /**
     * Approve revocation
     */
    const approveRevocation = async (data) => {

      loadings.overlay = true

      const payload = {
        exclusionRevocationId: data.item._id,
        approbation: true
      };

      const type = "app-revocation-list/approveRevocation"

      await store.dispatch(type, payload).then(r => {
        showToast(vm.proxy.$t('labels.revocation_approved'), 'primary')
        loadings.overlay = false
        fetchData()
      }).catch(async (error) => {
        const tError = await translatableText({
          text: axiosErrorHandle(error)
        })
        showToast(tError, 'danger', 'XIcon')
        loadings.overlay = false
      })
    }

    const fillDataMeta = (response) => {

      const {data} = response
      totalRows.value = data.total;
      perPage.value = data.perPage;
      currentPage.value = data.page;

      dataMeta.value.from = perPage.value * (currentPage.value - 1) + (totalRows.value ? 1 : 0)
      dataMeta.value.to = perPage.value * (currentPage.value - 1) + totalRows.value
      dataMeta.value.of = totalRows.value;
    }

    const showToast = (msg, variant, icon) => {
      toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: icon ? icon : '',
          variant: variant,
        },
      });
    }

    return {
      perPageOptions,
      perPage,
      sortBy,
      searchQuery,
      authorized,
      fetchData,
      isSeePermission,
      approveRevocation,
      dataMeta,
      revocationList,
      tableColumns,
      isSortDirDesc,
      currentPage,
      loadings
    }
  },

  methods: {
    formatDate(value) {
      return moment(value).format('DD/MM/YYYY hh:mm a')
    }
  },

  }
</script>
