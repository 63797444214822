import axios from "@axios";

const APP_URL = process.env.VUE_APP_URL;
export default {
  namespaced: true,
  state: {
    revocationNotification : []
  },
  getters: {},
  mutations: {},
  actions: {
    fetchRevocations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/exclusion-revocation`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    approveRevocation(ctx, payload ) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APP_URL}/exclusion-revocation/approve`, payload)
          .then(response => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
